/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: $txt_color;
  background: $bg_color;
  font-size: 14px;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}
a {
  color: $txt_color;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}
.en {
  font-family: 'Montserrat', sans-serif;
}
strong {
  font-weight: bold;
}

/* wrapper
-------------------------*/
.wrapper {
	position: relative;
	left: 0;
	transition: left 300ms;
	min-width: 1000px;
	@include mq(sp) {
		min-width: inherit;
	}
	
	&.is_open {
		left: -260px;
		.header {
			left: -260px;
		}
		.gnav {
			right: 0;
		}
		.layer {
			display: block;
		}
		.header__menu-btn {
			&:before {
				transform: rotate(45deg);
				border-bottom: none;
				top: 6px;
				left: -5px;
			}
			&:after {
				transform: rotate(-45deg);
				border-bottom: none;
				top: 8px;
				left: 1px;
			}
		}
	}
}
.layer {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(desaturate($main_color,90%),.6);
	z-index: 92;
}

/* breadcrumb
-------------------------*/
.breadcrumb {
	padding: 20px 0;
	@include mq(sp) {
		display: none;
	}
  &__item {
		display: inline-block;
		font-size: 13px;
		margin-right: 5px;
		&:last-child {
			font-weight: bold;
			&:after {
				display: none;
			}
		}
		&:after {
			content: ">";
		}
		a {
			padding-right: 8px;
		}
    &--current {
      cursor: default;
      color: $txt_color;
    }
  }
}

/* contents
-------------------------*/
.contents {
	background-color: rgba(lighten($main_color,45%),.3);
	@include mq(sp) {
		padding-top: 50px;
	}
}
