/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
	left: 50%;
  display: block;
  width: 36px;
  height: 36px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  background: #fff;
  transform: translateY(-50%);
  z-index: 5;
  font-size: 0;
  line-height: 0;
	@include mq(sp) {
		display: none !important;
	}
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    width: 7px;
    height: 7px;
    margin-top: -4px;
    border-top: 1px solid #333;
  }
}
.slick-prev {
	margin-left: -518px;
  &:before {
    left: 16px;
    border-left: 1px solid #333;
    transform: rotate(-45deg);
  }
}
.slick-next {
  margin-left: 483px;
  &:before {
    right: 16px;
    border-right: 1px solid #333;
    transform: rotate(45deg);
  }
}
.slick-dots {
  position: absolute;
	bottom: 15px;
  left: 50%;
	margin-left: -478px;
	@include mq(sp) {
		bottom: 10px;
		left: 0;
		width: 100%;
		margin-left: 0;
		text-align: center;
	}
  li {
    display: inline-block;
    margin-right: 8px;
  }
  button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    border-radius: 50%;
    outline: none;
    background: #fff;
    z-index: 5;
    font-size: 0;
    line-height: 0;
  }
  .slick-active {
    button {
      background: $main_color;
    }
  }
}
.slick-current {
  opacity: 1 !important;
}