/* header
-------------------------*/
.header {
	position: relative;
  padding: 17px 0 0;
  background: #fff;
	box-sizing: border-box;
	@include mq(sp) {
		position: fixed;
		height: 50px;
		top: 0;
		left: 0;
		width: 100%;
		padding: 15px 15px 14px;
		text-align: center;
		background: rgba(#fff,.7);
		box-shadow: 0 1px 1px #f0f0f0;
		z-index: 95;
		transition: left 300ms;
	}
  .container {
    @include clearfix;
  }
  &__logo {
		display: block;
    float: left;
    height: auto;
    margin-bottom: 17px;
		@include mq(sp) {
			display: inline-block;
			float: none;
			margin: 0 auto;
			height: 19px;
		}
    
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
	&__menu-btn {
		display: none;
		position: absolute;
		top: 15px;
		right: 15px;
		width: 24px;
		height: 19px;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			border-top: 3px solid $main_color;
			border-bottom: 3px solid $main_color;
			content: "";
			transition: all 300ms;
		}
		&:after {
			position: absolute;
			top: 8px;
			left: 0;
			width: 100%;
			height: 3px;
			background: $main_color;
			content: "";
			transition: all 300ms;
		}
		@include mq(sp) {
			display: block;
		}
	}
}