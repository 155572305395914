/* ------------------------------------------------
  TOP
---------------------------------------------- */
.section {
	&:nth-child(even) {
		background-color: #fff;
	}
}
.slider {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
	&__list.only {
		background: #ddd;
		@include mq(sp) {
			padding: 0;
		}
		.slider__item {
			opacity: 1;
			margin: 0 auto;
		}
	}
  &__item {
    position: relative;
    opacity: .5;
		width: 1000px;
		height: 500px;
		overflow: hidden;
		@include mq(sp) {
			width: 100%;
			height: auto;
		}
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200px;
      content: "";
			@include gradient(transparent, rgba(#000,.7), vertical);
      z-index: 1;
			@include mq(sp) {
				display: none;
			}
    }
  }
  &__img {
		height: 500px;
    overflow: hidden;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		@include mq(sp) {
			height: 180px;
		}
  }
  img {
		display: none;
    width: 100%;
    height: auto;
    transition: transform 300ms;
		@include mq(sp) {
		}
  }
	&__txt-wrap {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 85px;
		box-sizing: border-box;
		padding: 0 20px;
		z-index: 2;
		@include mq(sp) {
			position: relative;
			padding: 20px 10px 10px;
			background: #000;
		}
	}
  &__ttl {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    font-weight: bold;
		@include mq(sp) {
			font-size: 12px;
		}
  }
  &__category {
    color: $main_color;
    font-weight: bold;
		@include mq(sp) {
			display: block;
			font-size: 10px;
		}
  }
  &__pickup {
    position: absolute;
    left: 50%;
    bottom: 110px;
		margin-left: -510px;
    color: #fff;
    font-size: 20px;
    letter-spacing: .1em;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 5px;
    background: $icon_color;
    font-weight: bold;
    z-index: 3;
		@include mq(sp) {
			bottom: 72px;
			height: 25px;
			line-height: 25px;
			left: 0;
			font-size: 10px;
		}
    &:before {
      position: absolute;
      top: 0;
      right: -20px;
      border-left: 20px solid $icon_color;
      border-top: 40px solid transparent;
      content: "";
      z-index: 3;
			@include mq(sp) {
				border-top: 25px solid transparent;
			}
    }
    &:after {
      position: absolute;
      bottom: -10px;
      left: 0;
      border-right: 10px solid darken( $icon_color, 40% );
      border-bottom: 10px solid transparent;
      content: "";
      z-index: 3;
			@include mq(sp) {
				bottom: 0;
				left: 100%;
				border-right: none;
				border-left: 20px solid $icon_color;
				border-bottom: 25px solid transparent;
			}
    }
    &-inner {
      display: block;
      position: relative;
      z-index: 2;
      &:before {
        position: absolute;
        bottom: -10px;
        left: -24px;
        border-bottom: 20px solid $icon_color;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        z-index: 2;
				@include mq(sp) {
					display: none;
				}
      }
      &:after {
        position: absolute;
        top: 10px;
        left: -24px;
        border-top: 20px solid $icon_color;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        z-index: 2;
				@include mq(sp) {
					display: none;
				}
      }
    }
  }
}
.article-list {
	display: flex;
	flex-wrap: wrap;
	padding: 0 20px;
	width: 100%;
	box-sizing: border-box;
	@include mq(sp) {
		margin-bottom: 20px;
		padding: 0 5px;
	}
	&__item {
		position: relative;
		width: 33.3%;
		margin-bottom: 20px;
		background: #fff;
		@include mq(sp) {
			width: 50%;
			margin-bottom: 10px;
			&:nth-child(odd) {
				left: -5px !important;
			}
			&:nth-child(even) {
				left: 5px !important;
			}
		}
		&:nth-child(3n) {
			left: 20px;
		}
		&:nth-child(3n+1) {
			left: -20px;
		}
		&--new {
			&:before {
				position: absolute;
				top: -10px;
				right: -10px;
				width: 32px;
				height: 32px;
				background: $icon_color;
				content: "";
				z-index: 2;
				border-radius: 50%;
				@include mq(sp) {
					right: inherit;
					left: -10px;
				}
			}
			&:after {
				position: absolute;
				top: -3px;
				right: -7px;
				font-size: 10px;
				color: #fff;
				font-weight: bold;
				content: "NEW";
				font-family: 'Montserrat', sans-serif;
				z-index: 3;
				@include mq(sp) {
					right: inherit;
					left: -7px;
				}
			}
		}
	}
	article {
		height: 100%;
	}
	.wrap {
		height: 100%;
		padding-bottom: 52px;
		color: $txt_color;
		transition: color 400ms;
		box-sizing: border-box;
		@include mq(sp) {
			@include clearfix;
			padding: 0 10px 27px;
			background: #fff;
		}
		&:hover {
			color: $main_color;
			img {
				transform: scale(1.1);
				@include mq(sp) {
					transform: scale(1);
				}
			}
		}
	}
	&__thumb {
		position: relative;
		height: 160px;
		overflow: hidden;
		margin-bottom: 30px;
		@include mq(sp) {
			margin: 0 -10px 10px;
			height: 120px;
		}
	}
	&__category {
		position: absolute;
		left: 0;
		bottom: 0;
		padding-left: 10px;
		padding-right: 5px;
		background: $main_color;
		color: #fff;
		font-weight: bold;
		height: 25px;
		line-height: 25px;
		font-size: 12px;
		@include mq(sp) {
			display: block;
			padding: 0;
			width: 100%;
			font-size: 10px;
			text-align: center;
		}
		&:before {
			position: absolute;
			top: 0;
			right: -12px;
			border-left: 12px solid $main_color;
			border-top: 25px solid transparent;
			content: "";
			@include mq(sp) {
				display: none;
			}
		}
	}
	img {
		@include rwd_img;
		transition: transform 400ms;
		@include mq(sp) {
			max-width: inherit;
			width: 100%;
		}
	}
	&__inner {
		padding: 0 30px;
		@include mq(sp) {
			padding: 0;
		}
	}
	&__ttl {
		margin-bottom: 10px;
		font-size: 18px;
		font-weight: bold;
		@include mq(sp) {
			font-size: 13px;
			margin-bottom: 5px;
			line-height: 1.5;
		}
	}
	&__txt {
		margin-bottom: 20px;
		@include mq(sp) {
			display: none;
		}
	}
	&__meta {
		@include clearfix;
		position: absolute;
		left: 0;
		bottom: 30px;
		width: 100%;
		padding: 0 30px;
		box-sizing: border-box;
		white-space: nowrap;
		@include mq(sp) {
			bottom: 10px;
			padding: 0 10px;
		}
		&-item {
			float: left;
			width: 50%;
			font-size: 13px;
			color: #777;
			box-sizing: border-box;
			@include mq(sp) {
				font-size: 10px;
			}
			&--date {
				padding-left: 20px;
				background-image: url(../img/icon/ico_date.png);
				background-repeat: no-repeat;
				background-position: left center;
				background-size: 11px auto;
				@include mq(sp) {
					padding-left: 15px;
				}
			}
			&--like {
				padding-left: 40px;
				font-weight: bold;
				border-left: 1px solid #E2E2E2;
				background-image: url(../img/icon/ico_like.png);
				background-repeat: no-repeat;
				background-position: 20px center;
				background-size: 12px auto;
				@include mq(sp) {
					border-left: 0;
					padding-left: 35px;
				}
			}
		}
	}
	&--archive {
		@include mq(sp) {
			padding-bottom: 40px;
		}
	}
}
.contents-top {
	.article-list {
		padding-bottom: 0 !important;
	}
}
.ranking {
	padding-bottom: 60px;
	@include mq(sp) {
		padding: 0 0 30px;
	}
	&__ttl {
		position: relative;
		margin-bottom: 10px;
		font-size: 34px;
		font-weight: bold;
		text-align: center;
		border-bottom: 3px solid $txt_color;
		letter-spacing: 0.1em;
		@include mq(sp) {
			font-size: 24px;
		}
		&-ico {
			position: relative;
			display: inline-block;
			width: 30px;
			height: 40px;
			vertical-align: top;
			@include mq(sp) {
				height: 30px;
			}
			&:before {
				position: absolute;
				left: 1px;
				bottom: 0;
				border-right: 17px solid transparent;
				border-bottom: 15px solid $icon_color;
				content: "";
			}
			&:after {
				position: absolute;
				right: 1px;
				bottom: 0;
				border-left: 17px solid transparent;
				border-bottom: 15px solid $icon_color;
				content: "";
			}
			&-inner-1 {
				&:before {
					position: absolute;
					bottom: 0;
					left: 5px;
					border-right: 10px solid transparent;
					border-left: 10px solid transparent;
					border-bottom: 20px solid $icon_color;
					content: "";
				}
				&:after {
					position: absolute;
					bottom: 20px;
					left: 12px;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					content: "";
					background: $icon_color;
				}
			}
			&-inner-2 {
				&:before {
					position: absolute;
					bottom: 15px;
					left: -2px;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					content: "";
					background: $icon_color;
				}
				&:after {
					position: absolute;
					bottom: 15px;
					right: -2px;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					content: "";
					background: $icon_color;
				}
			}
			&--before {
				margin-right: 5px;
			}
			&--after {
				margin-left: 5px;
			}
		}
	}
	&__list {
		@include clearfix;
		margin-left: -10px;
		display: flex;
		@include mq(sp) {
			display: block;
			margin: 0;
		}
	}
	article {
		height: 100%;
	}
	.wrap {
		height: 100%;
		padding-bottom: 23px;
		box-sizing: border-box;
		@include mq(sp) {
			padding: 10px;
			background: #fff;
			@include clearfix;
		}
		&:hover {
			.ranking__item-img {
				opacity: .6;
			}
		}
	}
	&__item {
		position: relative;
		width: 20%;
		box-sizing: border-box;
		margin-left: 10px;
		background: #fff;
		@include mq(sp) {
			width: 100%;
			margin-bottom: 10px;
			margin-left: 0;
		}
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 25px;
			height: 25px;
			background: #000;
			content: "";
			z-index: 2;
		}
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 25px;
			line-height: 25px;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			text-align: center;
			font-family: 'Montserrat', sans-serif;
			z-index: 3;
		}
		&:nth-child(1) {
			&:after {
				content: "1";
			}
		}
		&:nth-child(2) {
			&:after {
				content: "2";
			}
		}
		&:nth-child(3) {
			&:after {
				content: "3";
			}
		}
		&:nth-child(4) {
			&:after {
				content: "4";
			}
		}
		&:nth-child(5) {
			&:after {
				content: "5";
			}
		}
		&-img {
			position: relative;
			overflow: hidden;
			opacity: 1;
			transition: opacity 300ms;
			height: 96px;
			@include mq(sp) {
				width: 120px;
				height: auto;
				float: left;
			}
			img {
				@include rwd_img;
			}
		}
		&-ttl {
			margin-bottom: 10px;
			font-size: 13px;
			font-weight: bold;
			@include mq(sp) {
				margin-bottom: 0;
				line-height: 1.5;
				margin-left: 130px;
			}
		}
		&-inner {
			padding: 10px 20px;
			@include mq(sp) {
				padding: 0;
			}
		}
		&-category {
			font-size: 12px;
			font-weight: bold;
			color: $main_color;
			@include mq(sp) {
				display: block;
				font-size: 10px;
				margin-left: 130px;
			}
		}
		&-meta {
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			padding: 0 15px;
			@include mq(sp) {
				bottom: inherit;
				top: 5px;
				left: 0;
				padding: 0 15px 0 0;;
				text-align: right;
			}
			&-item {
				display: inline-block;
				font-size: 13px;
				color: #777;
				@include mq(sp) {
					font-size: 10px;
				}
				&--like {
					padding-left: 20px;
					font-weight: bold;
					background-image: url(../img/icon/ico_like.png);
					background-repeat: no-repeat;
					background-position: 0 center;
					background-size: 12px auto;
				}
			}
		}
	}
}
.staff {
	background: #fff;
	padding: 60px 0;
	@include mq(sp) {
		padding: 30px 0 20px;
	}
	&__ttl {
		font-size: 50px;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.1em;
		@include mq(sp) {
			font-size: 24px;
		}
		&-sub {
			margin-top: -10px;
			margin-bottom: 30px;
			text-align: center;
			font-size: 13px;
			font-weight: bold;
			@include mq(sp) {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}
	&__list {
		@include clearfix;
		margin-left: -25px;
		@include mq(sp) {
			margin: 0 -15px 0;
			text-align: center;
		}
	}
	&__item {
		position: relative;
		float: left;
		width: 230px;
		text-align: center;
		margin-left: 25px;
		margin-bottom: 25px;
		@include mq(sp) {
			display: inline-block;
			float: none;
			width: 150px;
			margin-left: 0;
			margin-bottom: 20px;
			box-sizing: border-box;
			vertical-align: top;
		}
		&:before {
			position: absolute;
			top: 200px;
			right: 35px;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background: $main_color;
			content: "";
			z-index: 2;
			@include mq(sp) {
				top: 130px;
				right: 25px;
			}
		}
		&:after {
			position: absolute;
			top: 205px;
			right: 40px;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background: url(../img/icon/ico_search.png);
			background-repeat: no-repeat;
			background-position: left top;
			background-size: 13px auto;
			content: "";
			z-index: 2;
			@include mq(sp) {
				top: 135px;
				right: 28px;
			}
		}
	}
	&__img {
		position: relative;
		overflow: hidden;
		width: 230px;
		height: 230px;
		border-radius: 50%;
		margin-bottom: 10px;
		z-index: 1;
		@include mq(sp) {
			width: 140px;
			height: 140px;
			margin: 0 auto 10px;
		}
		
		img {
			@include rwd_img;
			transition: transform 400ms;
		}
	}
	.wrap {
		transition: color 400ms;
		&:hover {
      color: $main_color;
      img {
        transform: scale(1.1);
      }
    }
	}
	&__dtl {
		font-size: 13px;
		@include mq(sp) {
			font-size: 10px;
		}
	}
	&__name {
		position: relative;
		font-size: 19px;
		font-weight: bold;
		padding-bottom: 5px;
		@include mq(sp) {
			font-size: 16px;
		}
		&:before {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 40px;
			height: 3px;
			background: $main_color;
			content: "";
		}
	}
}
.message {
	padding: 60px 0;
	@include mq(sp) {
		padding: 30px 0 20px;
	}
	&__ttl {
		font-size: 50px;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.1em;
		@include mq(sp) {
			font-size: 24px;
		}
		&-sub {
			margin-top: -10px;
			margin-bottom: 30px;
			text-align: center;
			font-size: 13px;
			font-weight: bold;
			@include mq(sp) {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}
	&__list {
		@include clearfix;
		margin-left: -20px;
		@include mq(sp) {
			margin-left: 0;
		}
	}
	&__item {
		width: 320px;
		float: left;
		margin-left: 20px;
		margin-bottom: 20px;
		background: #fff;
		border: 1px solid #eee;
		box-sizing: border-box;
		@include mq(sp) {
			width: 100%;
			float: none;
			margin-left: 0;
			margin-bottom: 10px;
		}
		&-inner {
			padding: 20px 30px;
			@include mq(sp) {
				margin-left: 90px;
				padding: 0;
			}
		}
	}
	&__img {
		position: relative;
		height: 198px;
		overflow: hidden;
		@include mq(sp) {
			width: 80px;
			height: auto;
			float: left;
		}
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#000,.6);
			box-sizing: border-box;
			padding-top: 65px;
			content: "Read";
			@extend .en;
			text-align: center;
			color: $main_color;
			transition: opacity 300ms;
			font-size: 30px;
			letter-spacing: 0.05em;
			opacity: 0;
		}
		&:after {
			position: absolute;
			top: 105px;
			left: 0;
			width: 100%;
			text-align: center;
			content: "メッセージを読む";
			font-size: 11px;
			color: #fff;
			font-weight: bold;
			opacity: 0;
			transition: opacity 300ms;
		}
		img {
			@include rwd_img;
		}
	}
	&__name {
		margin-bottom: 10px;
		padding-bottom: 10px;
		font-size: 13px;
		border-bottom: 1px dotted $txt_color;
		@include mq(sp) {
			padding-bottom: 5px;
			font-size: 10px;
		}
		strong {
			font-size: 17px;
			margin-left: 5px;
			@include mq(sp) {
				font-size: 14px;
			}
		}
	}
	&__txt {
		line-height: 1.6;
		@include mq(sp) {
			font-size: 12px;
		}
	}
	.wrap {
		position: relative;
		transition: all 400ms;
		outline: none;
		@include mq(sp) {
			padding: 10px 40px 10px 10px;
			overflow: hidden;
		}
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 5px solid transparent;
			box-sizing: border-box;
			transition: transform 0.3s, opacity 0.3s;
			content: "";
			@include mq(sp) {
				position: absolute;
				top: 50%;
				left: inherit;
				width: 0;
				height: 0;
				right: 15px;
				width: 8px;
				height: 8px;
				border-left: none;
				border-bottom: none;
				border-top: 2px solid #999;
				border-right: 2px solid #999;
				content: "";
				transform: rotate(45deg) translateY(-50%);
			}
		}
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 5px solid $main_color;
			box-sizing: border-box;
			transition: transform 0.3s, opacity 0.3s;
			content: "";
			opacity: 0;
			transform: translateY(-7px) translateX(6px);
			@include mq(sp) {
				display: none;
			}
		}
		&:hover {
			&:before {
				opacity: 0;
				transform: translateY(5px) translateX(-5px);
				@include mq(sp) {
					opacity: 1;
					transform: rotate(45deg) translateY(-50%) translateX(0);
				}
			}
			&:after {
				opacity: 1;
				transform: translateY(0px) translateX(0px);
			}
			.message__img {
				&:before,
				&:after {
					opacity: 1;
					@include mq(sp) {
						opacity: 0;
					}

				}
			}
    }
	}
}
@keyframes bgAnime {
  0% {
    background-position: 0 0;
	}
  100% {
    background-position: -100% 0;
	}
}

.company {
	background: #fff;
	padding: 60px 0 0;
	@include mq(sp) {
		padding: 30px 0;
	}
	>.container {
		@include clearfix;
		padding: 50px 0;
		@include mq(sp) {
			padding: 15px 15px;
		}
	}
	&__ttl {
		font-size: 50px;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.1em;
		@include mq(sp) {
			font-size: 24px;
		}
		&-sub {
			margin-top: -10px;
			margin-bottom: 30px;
			text-align: center;
			font-size: 13px;
			font-weight: bold;
			@include mq(sp) {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}
	&__bg {
		width: 100%;
    height: 158px;
    background-repeat: repeat-x;
    background-position: left top;
		background-size: auto 100%;
    animation: bgAnime 30s linear infinite;
    z-index: -1;
		@include mq(sp) {
			height: 75px;
		}
	}
	table {
		width: 540px;
		float: right;
		border-top: 1px solid #ddd;
		margin-left: 30px;
		@include mq(sp) {
			margin: 0;
			width: 100%;
			float: none;
			margin-bottom: 20px;
			font-size: 12px;
		}
		
		th {
			padding: 12px 15px;
			box-sizing: border-box;
			font-weight: bold;
			width: 120px;
			border-bottom: 1px solid #ddd;
			background-color: #f0f0f0;
			vertical-align: top;
			@include mq(sp) {
				width: 100px;
			}
		}
		td {
			padding: 12px 20px;
			border-bottom: 1px solid #ddd;
			background: #fff;
		}
	}
	&__dtl {
		margin-right: 580px;
		@include mq(sp) {
			margin-right: 0;
		}
	}
	img {
		max-width: 420px;
		margin-bottom: 10px;
	}
	@include mq(sp) {
		img {
			@include rwd_img;
			margin-bottom: 10px;
		}
	}
	&__txt {
		font-weight: bold;
		line-height: 1.8;
		@include mq(sp) {
			font-size: 12px;
			line-height: 1.6;
		}
	}
	&__map {
		width: 100%;
		height: 350px;
		@include mq(sp) {
			height: 200px;
		}
	}
}
.loop-slide {
	position: relative;
	@include mq(sp) {
		display: none !important;
	}
	li {
		float: left;
	}
	img {
		display: block;
	}
}
.top__body {
	padding: 35px 0px;
	@include mq(sp) {
		padding: 15px 15px 5px;
	}
	img {
		@include rwd_img;
	}
	h2 {
		position: relative;
		margin-bottom: 1em;
		font-size: 20px;
		font-weight: bold;
		line-height: 1.41;
		padding-bottom: 0.5em;
		border-bottom: 5px solid rgba($main_color,.1);
		@include mq(sp) {
			font-size: 18px;
		}
		&:before {
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 50px;
			height: 5px;
			background: $main_color;
			content: "";
		}
	}
	h3 {
		margin-bottom: 1em;
		padding-left: 12px;
		border-left: 3px solid $main_color;
		font-size: 18px;
		font-weight: bold;
		line-height: 1.41;
		@include mq(sp) {
			font-size: 16px;
		}
	}
	h4 {
		margin-bottom: 1em;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.41;
		@include mq(sp) {
			font-size: 14px;
		}
	}
	p {
		margin-bottom: 1.5em;
		font-size: 15px;
		line-height: 2;
		@include mq(sp) {
			font-size: 13px;
			line-height: 1.7;
		}
		a{
			color: $main_color;
			text-decoration: underline;
			background-image: url(../img/icon/ico_link.png);
			background-size: 4px auto;
			background-repeat: no-repeat;
			background-position: left center;
			padding-left: 12px;
			&:hover {
				text-decoration: none;
			}
		}
	}
	ul {
		margin-bottom: 1.5em;
		li {
			position: relative;
			line-height: 2;
			margin-bottom: 0.5em;
			padding-left: 14px;
			@include mq(sp) {
				line-height: 1.7;
				font-size: 13px;
			}
			&:before {
				position: absolute;
				top: 10px;
				left: 0;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: rgba($main_color,.3);
				content: "";
				@include mq(sp) {
					top: 7px;
				}
			}
		}
	}
	table {
		width: 100%;
		border-top: 1px solid #ddd;
		@include mq(sp) {
			float: none;
			margin-bottom: 20px;
			font-size: 12px;
		}

		th {
			padding: 12px 15px;
			box-sizing: border-box;
			font-weight: bold;
			width: 120px;
			border-bottom: 1px solid #ddd;
			background-color: #f0f0f0;
			vertical-align: top;
			@include mq(sp) {
				width: 100px;
			}
		}
		td {
			padding: 12px 20px;
			border-bottom: 1px solid #ddd;
			background: #fff;
		}
	}
}
#article {
	padding-bottom:60px;
}
.to-archives {
	text-align: center;
	font-weight:bold;
	a {
		text-decoration: underline;
	}
	a:hover {
		text-decoration: none;
	}
}

