/* ------------------------------------------------
  article
---------------------------------------------- */
.article {
	margin-bottom: 60px;
	@include clearfix;
	@include mq(sp) {
		margin-bottom: 40px;
		margin-top: 15px;
	}
	&__ttl-box {
		position: relative;
		width: 340px;
		margin-bottom: 20px;
		background: #fff;
		float: right;
		box-sizing: border-box;
		padding: 30px 20px 20px 10px;
		@include mq(sp) {
			padding: 15px;
			float: none;
			width: 100%;
			margin-bottom: 0;
		}
		&:before {
			position: absolute;
			top: 0;
			left: -20px;
			width: 20px;
			height: 100%;
			border-left: 3px solid $main_color;
			background: #fff;
			content: "";
			@include mq(sp) {
				display: none;
			}
		}
	}
	&__side {
		float: right;
		width: 320px;
		box-sizing: border-box;
		border: 1px solid #e2e2e2;
		padding: 10px 20px;
		background: rgba(lighten($main_color,45%),.3);
		@include mq(sp) {
			float: none;
			width: 100%;
			padding: 10px 15px;
		}
		&-ttl {
			margin-bottom: 10px;
			font-weight: bold;
		}
		&-list {
			&-item {
				margin-bottom: 20px;
			}
			.wrap {
				@include clearfix;
				&:hover {
					.article__side-list-ttl {
						text-decoration: underline;
					}
				}
			}
			&-thumb {
				width: 100px;
				height: 100px;
				overflow: hidden;
				float: left;
				@include mq(sp) {
					width: 80px;
					height: 80px;
				}
				img {
					position: relative;
					left: 50%;
					transform: translateX(-50%);
					display: block;
					height: 100%;
				}
			}
			&-category {
				margin-left: 120px;
				color: $main_color;
				font-weight: bold;
				font-size: 13px;
				@include mq(sp) {
					margin-left: 95px;
					font-size: 10px;
				}
			}
			&-ttl {
				margin-left: 120px;
				font-weight: bold;
				font-size: 13px;
				line-height: 1.6;
				@include mq(sp) {
					margin-left: 95px;
					font-size: 11px;
				}
			}
		}
	}
	&__meta {
		@include clearfix;
		margin-bottom: 10px;
		&-item {
			float: left;
			font-size: 12px;
			padding: 8px 20px;
			line-height: 1;
			text-align: center;
			@include mq(sp) {
				font-size: 10px;
			}
			&--category {
				background: $main_color;
				color: #fff;
				font-weight: bold;
				a {
					color: #fff;
					transition: opacity 300ms;
					&:hover {
						opacity: .6;
						text-decoration: none;
					}
				}
			}
			&--date {
				padding: 8px 15px 8px 30px;
				background-color: rgba(desaturate($main_color,90%),.2);
				background-image: url(../img/icon/ico_date.png);
				background-repeat: no-repeat;
				background-position: 10px center;
				background-size: 11px auto;
				@extend .en;
			}
		}
	}
	&__ttl {
		margin-bottom: 20px;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.41;
		@include mq(sp) {
			margin-bottom: 10px;
			font-size: 18px;
		}
	}
	&__tag {
		&-item {
			display: inline-block;
			margin-right: 3px;
			margin-bottom: 5px;
			padding: 5px 8px;
			background: rgba(desaturate($main_color,90%),.2);
			font-size: 11px;
			line-height: 1;
			@include mq(sp) {
				font-size: 10px;
			}
		}
	}
	&__main-img {
		img {
			@include rwd_img;
		}
	}
	&__body {
		width: 660px;
		float: left;
		box-sizing: border-box;
		background: #fff;
		@include mq(sp) {
			float: none;
			width: 100%;
			margin-bottom: 20px;
			padding-bottom: 1px;
		}
		&-inner {
			padding: 30px 50px;
			@include mq(sp) {
				padding: 15px 15px 5px;
			}
			img {
				@include rwd_img;
			}
			h2 {
				position: relative;
				margin-bottom: 1em;
				font-size: 20px;
				font-weight: bold;
				line-height: 1.41;
				padding-bottom: 0.5em;
				border-bottom: 5px solid rgba($main_color,.1);
				@include mq(sp) {
					font-size: 18px;
				}
				&:before {
					position: absolute;
					bottom: -5px;
					left: 0;
					width: 50px;
					height: 5px;
					background: $main_color;
					content: "";
				}
			}
			h3 {
				margin-bottom: 1em;
				padding-left: 12px;
				border-left: 3px solid $main_color;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.41;
				@include mq(sp) {
					font-size: 16px;
				}
			}
			h4 {
				margin-bottom: 1em;
				font-size: 16px;
				font-weight: bold;
				line-height: 1.41;
				@include mq(sp) {
					font-size: 14px;
				}
			}
			p {
				margin-bottom: 1.5em;
				font-size: 15px;
				line-height: 2;
				@include mq(sp) {
					font-size: 13px;
					line-height: 1.7;
				}
				a{
					color: $main_color;
					text-decoration: underline;
					background-image: url(../img/icon/ico_link.png);
					background-size: 4px auto;
					background-repeat: no-repeat;
					background-position: left center;
					padding-left: 12px;
					&:hover {
						text-decoration: none;
					}
				}
			}
			ul {
				margin-bottom: 1.5em;
				li {
					position: relative;
					line-height: 2;
					margin-bottom: 0.5em;
					padding-left: 14px;
					@include mq(sp) {
						line-height: 1.7;
						font-size: 13px;
					}
					&:before {
						position: absolute;
						top: 10px;
						left: 0;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						background: rgba($main_color,.3);
						content: "";
						@include mq(sp) {
							top: 7px;
						}
					}
				}
			}
			table {
				width: 100%;
				border-top: 1px solid #ddd;
				@include mq(sp) {
					float: none;
					margin-bottom: 20px;
					font-size: 12px;
				}

				th {
					padding: 12px 15px;
					box-sizing: border-box;
					font-weight: bold;
					width: 120px;
					border-bottom: 1px solid #ddd;
					background-color: #f0f0f0;
					vertical-align: top;
					@include mq(sp) {
						width: 100px;
					}
				}
				td {
					padding: 12px 20px;
					border-bottom: 1px solid #ddd;
					background: #fff;
				}
			}
		}
	}
	&__sns {
		margin: 10px 10px 15px;
		border: 1px solid #e2e2e2;
		padding: 10px 20px;
		background: rgba(desaturate($main_color,90%),.1);
		@include mq(sp) {
			padding: 10px;
		}
		li {
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			margin-right: 10px;
			@include mq(sp) {
				margin-bottom: 5px;
			}
		}
		#___plusone_0 {
			width: 60px !important;
		}
	}
	&__autor {
		position: relative;
		@include clearfix;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #e2e2e2;
		@include mq(sp) {
			padding-bottom: 15px;
		}
		&:before {
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 1px;
			background: #fff;
			content: "";
		}
		&-thumb {
			margin-bottom: 10px;
			width: 80px;
			height: 80px;
			border: 4px solid #fff;
			box-sizing: border-box;
			float: left;
			border-radius: 50%;
			img {
				@include rwd_img;
				border-radius: 50%;
			}
		}
		&-name {
			margin-left: 95px;
			padding-top: 15px;
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 2px;
		}
		&-dtl {
			margin-left: 95px;
			font-size: 13px;
			margin-bottom: 10px;
			&:before {
				content: "-";
				margin-right: 5px;
			}
		}
		&-txt {
			clear: both;
			line-height: 1.6;
			font-size: 13px;
			@include mq(sp) {
				font-size: 11px;
			}
		}
	}
}