/* ------------------------------------------------
  page
---------------------------------------------- */
.page-ttl {
	font-size: 50px;
	letter-spacing: 0.1em;
	text-align: center;
	font-weight: bold;
	@include mq(sp) {
		padding-top: 20px;
		font-size: 25px;
		line-height: 1.2;
	}
}
.page-sub-ttl {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	@include mq(sp) {
		font-size: 14px;
		margin-bottom: 20px;
	}
}
.page-list-ttl {
	margin-bottom: 10px;
	font-size: 36px;
	line-height: 1.6;
	@include mq(sp) {
		margin-bottom: 15px;
		font-size: 18px;
		text-align: center;
	}
}
.pager {
	text-align: center;
	padding-bottom: 60px;
	@extend .en;
	@include mq(sp) {
		padding-bottom: 40px;
	}
	&__item {
		display: inline-block;
		width: 35px;
		height: 35px;
		margin: 0 5px;
		box-sizing: border-box;
		background: #fff;
		border: 3px solid #fff;
		text-align: center;
		line-height: 29px;
		vertical-align: middle;
		font-size: 16px;
		margin: 0 3px;
		@include mq(sp) {
			font-size: 13px;
			width: 28px;
			height: 28px;
			line-height: 22px;
		}
		a {
			display: block;
			color: $main_color;
			font-weight: bold;
			text-decoration: none;
			transition: all 300ms;
			&:hover {
				background-color: rgba($main-color,0.07);
				color: #8f8887;
			}
		}
	}
	.current {
		a {
			background-color: rgba(lighten($main_color,45%),.3);;
			color: #8f8887;
		}
	}
	img {
		display: inline-block;
		width: 4px;
		vertical-align: middle;
		margin-top: -4px;
	}
}
.no-data {
	text-align: center;
	padding: 40px 50px;
    border: 1px solid #e2e2e2;
    background: #fff;
    margin-bottom:80px;
    margin-top:40px;
}
.main-contents{
    margin-top: 20px;
    padding-bottom: 80px;
	@include mq(sp) {
		padding: 30px 0;
	}
	&__inner {
		padding: 40px 50px;
    	border: 1px solid #e2e2e2;
    	background: #fff;
	}
	>.container {
		@include clearfix;
		padding: 50px 0;
		@include mq(sp) {
			padding: 15px 15px;
		}
	}
	&__ttl {
		font-size: 50px;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.1em;
		@include mq(sp) {
			font-size: 24px;
		}
		&-sub {
			margin-top: -10px;
			margin-bottom: 30px;
			text-align: center;
			font-size: 13px;
			font-weight: bold;
			@include mq(sp) {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}
	&__bg {
		width: 100%;
    height: 158px;
    background-repeat: repeat-x;
    background-position: left top;
		background-size: auto 100%;
    animation: bgAnime 30s linear infinite;
    z-index: -1;
		@include mq(sp) {
			height: 75px;
		}
	}
	table {
		border-top: 1px solid #ddd;
		@include mq(sp) {
			width: 100%;
			float: none;
			margin-bottom: 20px;
			font-size: 12px;
		}

		th {
			padding: 12px 15px;
			box-sizing: border-box;
			font-weight: bold;
			width: 120px;
			border-bottom: 1px solid #ddd;
			background-color: #f0f0f0;
			vertical-align: top;
			@include mq(sp) {
				width: 100px;
			}
		}
		td {
			padding: 12px 20px;
			border-bottom: 1px solid #ddd;
			background: #fff;
		}
	}
	&__dtl {
		margin-right: 580px;
		@include mq(sp) {
			margin-right: 0;
		}
	}
	&__img {
		margin-bottom: 10px;
		img {
			@include rwd_img;
		}
	}
	&__txt {
		font-weight: bold;
		line-height: 1.8;
		@include mq(sp) {
			font-size: 12px;
			line-height: 1.6;
		}
	}
	&__map {
		width: 100%;
		height: 350px;
		@include mq(sp) {
			height: 200px;
		}
	}
}
.main-contents-company {
	@include mq(sp) {
		padding: 30px 0;
	}
	>.container {
		@include clearfix;
		padding: 50px 0;
		@include mq(sp) {
			padding: 15px 15px;
		}
	}
	.main-contents__inner {
		overflow: hidden;
	}
	table {
		width: 440px;
		float: right;
		margin-left:30px;
		border-top: 1px solid #ddd;
		@include mq(sp) {
			margin: 0;
			width: 100%;
			float: none;
			margin-bottom: 20px;
			font-size: 12px;
		}

		th {
			padding: 12px 15px;
			box-sizing: border-box;
			font-weight: bold;
			width: 120px;
			border-bottom: 1px solid #ddd;
			background-color: #f0f0f0;
			vertical-align: top;
			@include mq(sp) {
				width: 100px;
			}
		}
		td {
			padding: 12px 20px;
			border-bottom: 1px solid #ddd;
			background: #fff;
		}
	}
	img {
		max-width: 420px;
		margin-bottom: 10px;
	}
	@include mq(sp) {
		img {
			@include rwd_img;
			margin-bottom: 10px;
		}
	}
}
.contents {
	.article-list {
		padding-top:40px;
		padding-bottom:80px;
	}
}
.contents-media {
	.page-ttl {
		//margin-bottom:40px;
	}
}