/* footer
-------------------------*/
.footer {
	&__top {
		padding: 30px 0;
		background: #000;
		text-align: center;
	}
	&__logo {
		margin-bottom: 15px;
		text-align: center;
	}
	&__sns {

		&-ttl {
			display: inline-block;
			margin-bottom: 10px;
			border-top: 1px solid #fff;
			font-size: 11px;
			letter-spacing: 0.1em;
			color: #fff;
			
		}
		&-list {
			li {
				display: inline-block;
				width: 42px;
				margin: 0 10px;
				@include mq(sp) {
					margin: 0 5px;
				}
				img {
					@include rwd_img;
				}
			}
		}
	}
	&__middle {
		padding: 30px 0;
		background: #555;
		text-align: center;
		@include mq(sp) {
			display: none;
		}
	}
	&__link {
		display: inline-block;
		margin: 0 25px;
		vertical-align: top;
		&-ttl {
			position: relative;
			color: #fff;
			margin-bottom: 10px;
			font-weight: bold;
			text-align: left;
			padding-left: 14px;
			line-height: 1;
			&:before {
				position: absolute;
				bottom: 2px;
				left: 0;
				width: 10px;
				height: 1px;
				background: #fff;
				content: "";
			}
		}
		&-list {
			text-align: left;
			margin-left: 14px;
			li {
				line-height: 1.8;
			}
			a {
				color: #fff;
			}
		}
	}
	&__bottom {
		padding: 30px 0;
		background: #fff;
		color: #525252;
		@include mq(sp) {
			padding: 15px 15px;
			font-size: 12px;
		}
		a {
			color: #525252;
		}
		>.container {
			@include clearfix;
		}
		&-left {
			float: left;
			@include mq(sp) {
				float: none;
				margin-bottom: 10px;
			}
		}
		&-right {
			float: right;
			@include mq(sp) {
				float: none;
			}
		}
		&-list {
			@include mq(sp) {
				display: none;
			}
			li {
				display: inline-block;
				font-size: 12px;
				border-left: 1px dotted #525252;
				line-height: 1;
				padding: 0 8px;
				&:first-child {
					border-left: none;
				}
			}
		}
	}
	&__copyright {
		font-size: 11px;
		text-align: right;
		@include mq(sp) {
			text-align: center;
			font-size: 10px;
		}
	}
}
