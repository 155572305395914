@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/*clear fix*/
/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #333;
  background: #fff;
  font-size: 14px;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.en, .message__img:before, .pager, .article__meta-item--date {
  font-family: 'Montserrat', sans-serif;
}

strong {
  font-weight: bold;
}

/* wrapper
-------------------------*/
.wrapper {
  position: relative;
  left: 0;
  transition: left 300ms;
  min-width: 1000px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    min-width: inherit;
  }
}

.wrapper.is_open {
  left: -260px;
}

.wrapper.is_open .header {
  left: -260px;
}

.wrapper.is_open .gnav {
  right: 0;
}

.wrapper.is_open .layer {
  display: block;
}

.wrapper.is_open .header__menu-btn:before {
  transform: rotate(45deg);
  border-bottom: none;
  top: 6px;
  left: -5px;
}

.wrapper.is_open .header__menu-btn:after {
  transform: rotate(-45deg);
  border-bottom: none;
  top: 8px;
  left: 1px;
}

.layer {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(115, 134, 140, 0.6);
  z-index: 92;
}

/* breadcrumb
-------------------------*/
.breadcrumb {
  padding: 20px 0;
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__item {
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
}

.breadcrumb__item:last-child {
  font-weight: bold;
}

.breadcrumb__item:last-child:after {
  display: none;
}

.breadcrumb__item:after {
  content: ">";
}

.breadcrumb__item a {
  padding-right: 8px;
}

.breadcrumb__item--current {
  cursor: default;
  color: #333;
}

/* contents
-------------------------*/
.contents {
  background-color: rgba(230, 249, 255, 0.3);
}

@media screen and (max-width: 768px) {
  .contents {
    padding-top: 50px;
  }
}

/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.container {
  width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .container {
    width: auto;
    padding: 0 15px;
  }
}

.wrap, .fade {
  display: block;
  text-decoration: none;
}

.wrap:hover, .fade:hover {
  text-decoration: none;
}

.ib {
  display: inline-block;
}

.phoneTxt {
  text-decoration: none;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .is_pc {
    display: none;
  }
}

.is_sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .is_sp {
    display: block !important;
  }
}

/* header
-------------------------*/
.header {
  position: relative;
  padding: 17px 0 0;
  background: #fff;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    height: 50px;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 15px 14px;
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 1px 1px #f0f0f0;
    z-index: 95;
    transition: left 300ms;
  }
}

.header .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.header__logo {
  display: block;
  float: left;
  height: auto;
  margin-bottom: 17px;
}

@media screen and (max-width: 768px) {
  .header__logo {
    display: inline-block;
    float: none;
    margin: 0 auto;
    height: 19px;
  }
}

.header__logo img {
  display: block;
  width: auto;
  height: 100%;
}

.header__menu-btn {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 19px;
}

.header__menu-btn:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-top: 3px solid #00BFFF;
  border-bottom: 3px solid #00BFFF;
  content: "";
  transition: all 300ms;
}

.header__menu-btn:after {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #00BFFF;
  content: "";
  transition: all 300ms;
}

@media screen and (max-width: 768px) {
  .header__menu-btn {
    display: block;
  }
}

/* footer
-------------------------*/
.footer__top {
  padding: 30px 0;
  background: #000;
  text-align: center;
}

.footer__logo {
  margin-bottom: 15px;
  text-align: center;
}

.footer__sns-ttl {
  display: inline-block;
  margin-bottom: 10px;
  border-top: 1px solid #fff;
  font-size: 11px;
  letter-spacing: 0.1em;
  color: #fff;
}

.footer__sns-list li {
  display: inline-block;
  width: 42px;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .footer__sns-list li {
    margin: 0 5px;
  }
}

.footer__sns-list li img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.footer__middle {
  padding: 30px 0;
  background: #555;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer__middle {
    display: none;
  }
}

.footer__link {
  display: inline-block;
  margin: 0 25px;
  vertical-align: top;
}

.footer__link-ttl {
  position: relative;
  color: #fff;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
  padding-left: 14px;
  line-height: 1;
}

.footer__link-ttl:before {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 10px;
  height: 1px;
  background: #fff;
  content: "";
}

.footer__link-list {
  text-align: left;
  margin-left: 14px;
}

.footer__link-list li {
  line-height: 1.8;
}

.footer__link-list a {
  color: #fff;
}

.footer__bottom {
  padding: 30px 0;
  background: #fff;
  color: #525252;
}

@media screen and (max-width: 768px) {
  .footer__bottom {
    padding: 15px 15px;
    font-size: 12px;
  }
}

.footer__bottom a {
  color: #525252;
}

.footer__bottom > .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.footer__bottom-left {
  float: left;
}

@media screen and (max-width: 768px) {
  .footer__bottom-left {
    float: none;
    margin-bottom: 10px;
  }
}

.footer__bottom-right {
  float: right;
}

@media screen and (max-width: 768px) {
  .footer__bottom-right {
    float: none;
  }
}

@media screen and (max-width: 768px) {
  .footer__bottom-list {
    display: none;
  }
}

.footer__bottom-list li {
  display: inline-block;
  font-size: 12px;
  border-left: 1px dotted #525252;
  line-height: 1;
  padding: 0 8px;
}

.footer__bottom-list li:first-child {
  border-left: none;
}

.footer__copyright {
  font-size: 11px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .footer__copyright {
    text-align: center;
    font-size: 10px;
  }
}

/* gnav
-------------------------*/
.gnav {
  background: #00BFFF;
  z-index: 90;
}

@media screen and (max-width: 768px) {
  .gnav {
    position: fixed;
    top: 0;
    right: -260px;
    width: 260px;
    height: 100%;
    box-sizing: border-box;
    text-align: left;
    transition: right 300ms;
  }
}

.gnav .container {
  position: relative;
}

@media screen and (max-width: 768px) {
  .gnav .container {
    height: 100%;
    overflow: auto;
    padding: 0;
  }
}

.gnav__header-list {
  position: absolute;
  top: -100%;
  right: 0;
  margin-top: 7px;
  margin-bottom: 17px;
}

@media screen and (max-width: 768px) {
  .gnav__header-list {
    position: relative;
    top: 0;
    right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
  }
  .gnav__header-list:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '';
  }
}

.gnav__header-item {
  display: inline-block;
  border-left: 1px dotted #6A6A6A;
  line-height: 1;
  padding: 0 10px;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .gnav__header-item {
    display: block;
    border-left: none;
    padding: 0;
    width: 50%;
    float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
  }
  .gnav__header-item:nth-child(even) {
    border-right: none;
  }
}

.gnav__header-item:first-child {
  border-left: none;
}

.gnav__header-item a {
  display: block;
  color: #535353;
}

@media screen and (max-width: 768px) {
  .gnav__header-item a {
    padding: 15px 10px;
    color: #fff;
    font-weight: bold;
  }
}

.gnav__list {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .gnav__list {
    text-align: left;
    margin-bottom: 40px;
  }
}

.gnav__item {
  display: inline-block;
  padding: 6px 0;
}

@media screen and (max-width: 768px) {
  .gnav__item {
    display: block;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  }
}

.gnav__item a {
  position: relative;
  display: block;
  padding: 6px 15px;
  border-radius: 2px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  transition: all 300ms;
}

@media screen and (max-width: 768px) {
  .gnav__item a {
    padding: 10px 15px;
  }
  .gnav__item a:before {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    content: "";
    transform: rotate(45deg) translateY(-50%);
  }
}

.gnav__ttl {
  display: none;
  margin-bottom: 5px;
  padding: 0 10px;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .gnav__ttl {
    display: block;
  }
}

.fixed .gnav {
  position: fixed;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .fixed .gnav {
    width: 260px;
  }
}

.fixed + .contents {
  margin-top: 47px;
}

@media screen and (max-width: 768px) {
  .fixed + .contents {
    margin-top: 0;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 36px;
  height: 36px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  background: #fff;
  transform: translateY(-50%);
  z-index: 5;
  font-size: 0;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.slick-prev:before,
.slick-next:before {
  position: absolute;
  content: "";
  top: 50%;
  width: 7px;
  height: 7px;
  margin-top: -4px;
  border-top: 1px solid #333;
}

.slick-prev {
  margin-left: -518px;
}

.slick-prev:before {
  left: 16px;
  border-left: 1px solid #333;
  transform: rotate(-45deg);
}

.slick-next {
  margin-left: 483px;
}

.slick-next:before {
  right: 16px;
  border-right: 1px solid #333;
  transform: rotate(45deg);
}

.slick-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -478px;
}

@media screen and (max-width: 768px) {
  .slick-dots {
    bottom: 10px;
    left: 0;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
}

.slick-dots li {
  display: inline-block;
  margin-right: 8px;
}

.slick-dots button {
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  background: #fff;
  z-index: 5;
  font-size: 0;
  line-height: 0;
}

.slick-dots .slick-active button {
  background: #00BFFF;
}

.slick-current {
  opacity: 1 !important;
}

/* ------------------------------------------------
  TOP
---------------------------------------------- */
.section:nth-child(even) {
  background-color: #fff;
}

.slider {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
}

.slider__list.only {
  background: #ddd;
}

@media screen and (max-width: 768px) {
  .slider__list.only {
    padding: 0;
  }
}

.slider__list.only .slider__item {
  opacity: 1;
  margin: 0 auto;
}

.slider__item {
  position: relative;
  opacity: .5;
  width: 1000px;
  height: 500px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .slider__item {
    width: 100%;
    height: auto;
  }
}

.slider__item:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  content: "";
  background: transparent;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .slider__item:before {
    display: none;
  }
}

.slider__img {
  height: 500px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .slider__img {
    height: 180px;
  }
}

.slider img {
  display: none;
  width: 100%;
  height: auto;
  transition: transform 300ms;
}

.slider__txt-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85px;
  box-sizing: border-box;
  padding: 0 20px;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .slider__txt-wrap {
    position: relative;
    padding: 20px 10px 10px;
    background: #000;
  }
}

.slider__ttl {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .slider__ttl {
    font-size: 12px;
  }
}

.slider__category {
  color: #00BFFF;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .slider__category {
    display: block;
    font-size: 10px;
  }
}

.slider__pickup {
  position: absolute;
  left: 50%;
  bottom: 110px;
  margin-left: -510px;
  color: #fff;
  font-size: 20px;
  letter-spacing: .1em;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 5px;
  background: #FD6A62;
  font-weight: bold;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .slider__pickup {
    bottom: 72px;
    height: 25px;
    line-height: 25px;
    left: 0;
    font-size: 10px;
  }
}

.slider__pickup:before {
  position: absolute;
  top: 0;
  right: -20px;
  border-left: 20px solid #FD6A62;
  border-top: 40px solid transparent;
  content: "";
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .slider__pickup:before {
    border-top: 25px solid transparent;
  }
}

.slider__pickup:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  border-right: 10px solid #910902;
  border-bottom: 10px solid transparent;
  content: "";
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .slider__pickup:after {
    bottom: 0;
    left: 100%;
    border-right: none;
    border-left: 20px solid #FD6A62;
    border-bottom: 25px solid transparent;
  }
}

.slider__pickup-inner {
  display: block;
  position: relative;
  z-index: 2;
}

.slider__pickup-inner:before {
  position: absolute;
  bottom: -10px;
  left: -24px;
  border-bottom: 20px solid #FD6A62;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .slider__pickup-inner:before {
    display: none;
  }
}

.slider__pickup-inner:after {
  position: absolute;
  top: 10px;
  left: -24px;
  border-top: 20px solid #FD6A62;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .slider__pickup-inner:after {
    display: none;
  }
}

.article-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .article-list {
    margin-bottom: 20px;
    padding: 0 5px;
  }
}

.article-list__item {
  position: relative;
  width: 33.3%;
  margin-bottom: 20px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .article-list__item {
    width: 50%;
    margin-bottom: 10px;
  }
  .article-list__item:nth-child(odd) {
    left: -5px !important;
  }
  .article-list__item:nth-child(even) {
    left: 5px !important;
  }
}

.article-list__item:nth-child(3n) {
  left: 20px;
}

.article-list__item:nth-child(3n+1) {
  left: -20px;
}

.article-list__item--new:before {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 32px;
  height: 32px;
  background: #FD6A62;
  content: "";
  z-index: 2;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .article-list__item--new:before {
    right: inherit;
    left: -10px;
  }
}

.article-list__item--new:after {
  position: absolute;
  top: -3px;
  right: -7px;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  content: "NEW";
  font-family: 'Montserrat', sans-serif;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .article-list__item--new:after {
    right: inherit;
    left: -7px;
  }
}

.article-list article {
  height: 100%;
}

.article-list .wrap {
  height: 100%;
  padding-bottom: 52px;
  color: #333;
  transition: color 400ms;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .article-list .wrap {
    padding: 0 10px 27px;
    background: #fff;
  }
  .article-list .wrap:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '';
  }
}

.article-list .wrap:hover {
  color: #00BFFF;
}

.article-list .wrap:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .article-list .wrap:hover img {
    transform: scale(1);
  }
}

.article-list__thumb {
  position: relative;
  height: 160px;
  overflow: hidden;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .article-list__thumb {
    margin: 0 -10px 10px;
    height: 120px;
  }
}

.article-list__category {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 10px;
  padding-right: 5px;
  background: #00BFFF;
  color: #fff;
  font-weight: bold;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .article-list__category {
    display: block;
    padding: 0;
    width: 100%;
    font-size: 10px;
    text-align: center;
  }
}

.article-list__category:before {
  position: absolute;
  top: 0;
  right: -12px;
  border-left: 12px solid #00BFFF;
  border-top: 25px solid transparent;
  content: "";
}

@media screen and (max-width: 768px) {
  .article-list__category:before {
    display: none;
  }
}

.article-list img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
  transition: transform 400ms;
}

@media screen and (max-width: 768px) {
  .article-list img {
    max-width: inherit;
    width: 100%;
  }
}

.article-list__inner {
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .article-list__inner {
    padding: 0;
  }
}

.article-list__ttl {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .article-list__ttl {
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 1.5;
  }
}

.article-list__txt {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .article-list__txt {
    display: none;
  }
}

.article-list__meta {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  white-space: nowrap;
}

.article-list__meta:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .article-list__meta {
    bottom: 10px;
    padding: 0 10px;
  }
}

.article-list__meta-item {
  float: left;
  width: 50%;
  font-size: 13px;
  color: #777;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .article-list__meta-item {
    font-size: 10px;
  }
}

.article-list__meta-item--date {
  padding-left: 20px;
  background-image: url(../img/icon/ico_date.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 11px auto;
}

@media screen and (max-width: 768px) {
  .article-list__meta-item--date {
    padding-left: 15px;
  }
}

.article-list__meta-item--like {
  padding-left: 40px;
  font-weight: bold;
  border-left: 1px solid #E2E2E2;
  background-image: url(../img/icon/ico_like.png);
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 12px auto;
}

@media screen and (max-width: 768px) {
  .article-list__meta-item--like {
    border-left: 0;
    padding-left: 35px;
  }
}

@media screen and (max-width: 768px) {
  .article-list--archive {
    padding-bottom: 40px;
  }
}

.contents-top .article-list {
  padding-bottom: 0 !important;
}

.ranking {
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .ranking {
    padding: 0 0 30px;
  }
}

.ranking__ttl {
  position: relative;
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  border-bottom: 3px solid #333;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .ranking__ttl {
    font-size: 24px;
  }
}

.ranking__ttl-ico {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 40px;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .ranking__ttl-ico {
    height: 30px;
  }
}

.ranking__ttl-ico:before {
  position: absolute;
  left: 1px;
  bottom: 0;
  border-right: 17px solid transparent;
  border-bottom: 15px solid #FD6A62;
  content: "";
}

.ranking__ttl-ico:after {
  position: absolute;
  right: 1px;
  bottom: 0;
  border-left: 17px solid transparent;
  border-bottom: 15px solid #FD6A62;
  content: "";
}

.ranking__ttl-ico-inner-1:before {
  position: absolute;
  bottom: 0;
  left: 5px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 20px solid #FD6A62;
  content: "";
}

.ranking__ttl-ico-inner-1:after {
  position: absolute;
  bottom: 20px;
  left: 12px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  content: "";
  background: #FD6A62;
}

.ranking__ttl-ico-inner-2:before {
  position: absolute;
  bottom: 15px;
  left: -2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  content: "";
  background: #FD6A62;
}

.ranking__ttl-ico-inner-2:after {
  position: absolute;
  bottom: 15px;
  right: -2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  content: "";
  background: #FD6A62;
}

.ranking__ttl-ico--before {
  margin-right: 5px;
}

.ranking__ttl-ico--after {
  margin-left: 5px;
}

.ranking__list {
  margin-left: -10px;
  display: flex;
}

.ranking__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .ranking__list {
    display: block;
    margin: 0;
  }
}

.ranking article {
  height: 100%;
}

.ranking .wrap {
  height: 100%;
  padding-bottom: 23px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .ranking .wrap {
    padding: 10px;
    background: #fff;
  }
  .ranking .wrap:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '';
  }
}

.ranking .wrap:hover .ranking__item-img {
  opacity: .6;
}

.ranking__item {
  position: relative;
  width: 20%;
  box-sizing: border-box;
  margin-left: 10px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .ranking__item {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.ranking__item:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background: #000;
  content: "";
  z-index: 2;
}

.ranking__item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  line-height: 25px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  z-index: 3;
}

.ranking__item:nth-child(1):after {
  content: "1";
}

.ranking__item:nth-child(2):after {
  content: "2";
}

.ranking__item:nth-child(3):after {
  content: "3";
}

.ranking__item:nth-child(4):after {
  content: "4";
}

.ranking__item:nth-child(5):after {
  content: "5";
}

.ranking__item-img {
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity 300ms;
  height: 96px;
}

@media screen and (max-width: 768px) {
  .ranking__item-img {
    width: 120px;
    height: auto;
    float: left;
  }
}

.ranking__item-img img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.ranking__item-ttl {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .ranking__item-ttl {
    margin-bottom: 0;
    line-height: 1.5;
    margin-left: 130px;
  }
}

.ranking__item-inner {
  padding: 10px 20px;
}

@media screen and (max-width: 768px) {
  .ranking__item-inner {
    padding: 0;
  }
}

.ranking__item-category {
  font-size: 12px;
  font-weight: bold;
  color: #00BFFF;
}

@media screen and (max-width: 768px) {
  .ranking__item-category {
    display: block;
    font-size: 10px;
    margin-left: 130px;
  }
}

.ranking__item-meta {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .ranking__item-meta {
    bottom: inherit;
    top: 5px;
    left: 0;
    padding: 0 15px 0 0;
    text-align: right;
  }
}

.ranking__item-meta-item {
  display: inline-block;
  font-size: 13px;
  color: #777;
}

@media screen and (max-width: 768px) {
  .ranking__item-meta-item {
    font-size: 10px;
  }
}

.ranking__item-meta-item--like {
  padding-left: 20px;
  font-weight: bold;
  background-image: url(../img/icon/ico_like.png);
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 12px auto;
}

.staff {
  background: #fff;
  padding: 60px 0;
}

@media screen and (max-width: 768px) {
  .staff {
    padding: 30px 0 20px;
  }
}

.staff__ttl {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .staff__ttl {
    font-size: 24px;
  }
}

.staff__ttl-sub {
  margin-top: -10px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .staff__ttl-sub {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.staff__list {
  margin-left: -25px;
}

.staff__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .staff__list {
    margin: 0 -15px 0;
    text-align: center;
  }
}

.staff__item {
  position: relative;
  float: left;
  width: 230px;
  text-align: center;
  margin-left: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .staff__item {
    display: inline-block;
    float: none;
    width: 150px;
    margin-left: 0;
    margin-bottom: 20px;
    box-sizing: border-box;
    vertical-align: top;
  }
}

.staff__item:before {
  position: absolute;
  top: 200px;
  right: 35px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #00BFFF;
  content: "";
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .staff__item:before {
    top: 130px;
    right: 25px;
  }
}

.staff__item:after {
  position: absolute;
  top: 205px;
  right: 40px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: url(../img/icon/ico_search.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 13px auto;
  content: "";
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .staff__item:after {
    top: 135px;
    right: 28px;
  }
}

.staff__img {
  position: relative;
  overflow: hidden;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .staff__img {
    width: 140px;
    height: 140px;
    margin: 0 auto 10px;
  }
}

.staff__img img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
  transition: transform 400ms;
}

.staff .wrap {
  transition: color 400ms;
}

.staff .wrap:hover {
  color: #00BFFF;
}

.staff .wrap:hover img {
  transform: scale(1.1);
}

.staff__dtl {
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .staff__dtl {
    font-size: 10px;
  }
}

.staff__name {
  position: relative;
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .staff__name {
    font-size: 16px;
  }
}

.staff__name:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background: #00BFFF;
  content: "";
}

.message {
  padding: 60px 0;
}

@media screen and (max-width: 768px) {
  .message {
    padding: 30px 0 20px;
  }
}

.message__ttl {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .message__ttl {
    font-size: 24px;
  }
}

.message__ttl-sub {
  margin-top: -10px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .message__ttl-sub {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.message__list {
  margin-left: -20px;
}

.message__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .message__list {
    margin-left: 0;
  }
}

.message__item {
  width: 320px;
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #eee;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .message__item {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.message__item-inner {
  padding: 20px 30px;
}

@media screen and (max-width: 768px) {
  .message__item-inner {
    margin-left: 90px;
    padding: 0;
  }
}

.message__img {
  position: relative;
  height: 198px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .message__img {
    width: 80px;
    height: auto;
    float: left;
  }
}

.message__img:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  padding-top: 65px;
  content: "Read";
  text-align: center;
  color: #00BFFF;
  transition: opacity 300ms;
  font-size: 30px;
  letter-spacing: 0.05em;
  opacity: 0;
}

.message__img:after {
  position: absolute;
  top: 105px;
  left: 0;
  width: 100%;
  text-align: center;
  content: "メッセージを読む";
  font-size: 11px;
  color: #fff;
  font-weight: bold;
  opacity: 0;
  transition: opacity 300ms;
}

.message__img img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.message__name {
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  border-bottom: 1px dotted #333;
}

@media screen and (max-width: 768px) {
  .message__name {
    padding-bottom: 5px;
    font-size: 10px;
  }
}

.message__name strong {
  font-size: 17px;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .message__name strong {
    font-size: 14px;
  }
}

.message__txt {
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .message__txt {
    font-size: 12px;
  }
}

.message .wrap {
  position: relative;
  transition: all 400ms;
  outline: none;
}

@media screen and (max-width: 768px) {
  .message .wrap {
    padding: 10px 40px 10px 10px;
    overflow: hidden;
  }
}

.message .wrap:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  box-sizing: border-box;
  transition: transform 0.3s, opacity 0.3s;
  content: "";
}

@media screen and (max-width: 768px) {
  .message .wrap:before {
    position: absolute;
    top: 50%;
    left: inherit;
    width: 0;
    height: 0;
    right: 15px;
    width: 8px;
    height: 8px;
    border-left: none;
    border-bottom: none;
    border-top: 2px solid #999;
    border-right: 2px solid #999;
    content: "";
    transform: rotate(45deg) translateY(-50%);
  }
}

.message .wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #00BFFF;
  box-sizing: border-box;
  transition: transform 0.3s, opacity 0.3s;
  content: "";
  opacity: 0;
  transform: translateY(-7px) translateX(6px);
}

@media screen and (max-width: 768px) {
  .message .wrap:after {
    display: none;
  }
}

.message .wrap:hover:before {
  opacity: 0;
  transform: translateY(5px) translateX(-5px);
}

@media screen and (max-width: 768px) {
  .message .wrap:hover:before {
    opacity: 1;
    transform: rotate(45deg) translateY(-50%) translateX(0);
  }
}

.message .wrap:hover:after {
  opacity: 1;
  transform: translateY(0px) translateX(0px);
}

.message .wrap:hover .message__img:before, .message .wrap:hover .message__img:after {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .message .wrap:hover .message__img:before, .message .wrap:hover .message__img:after {
    opacity: 0;
  }
}

@keyframes bgAnime {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.company {
  background: #fff;
  padding: 60px 0 0;
}

@media screen and (max-width: 768px) {
  .company {
    padding: 30px 0;
  }
}

.company > .container {
  padding: 50px 0;
}

.company > .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .company > .container {
    padding: 15px 15px;
  }
}

.company__ttl {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .company__ttl {
    font-size: 24px;
  }
}

.company__ttl-sub {
  margin-top: -10px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .company__ttl-sub {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.company__bg {
  width: 100%;
  height: 158px;
  background-repeat: repeat-x;
  background-position: left top;
  background-size: auto 100%;
  animation: bgAnime 30s linear infinite;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .company__bg {
    height: 75px;
  }
}

.company table {
  width: 540px;
  float: right;
  border-top: 1px solid #ddd;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  .company table {
    margin: 0;
    width: 100%;
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.company table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .company table th {
    width: 100px;
  }
}

.company table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.company__dtl {
  margin-right: 580px;
}

@media screen and (max-width: 768px) {
  .company__dtl {
    margin-right: 0;
  }
}

.company img {
  max-width: 420px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .company img {
    display: block;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.company__txt {
  font-weight: bold;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  .company__txt {
    font-size: 12px;
    line-height: 1.6;
  }
}

.company__map {
  width: 100%;
  height: 350px;
}

@media screen and (max-width: 768px) {
  .company__map {
    height: 200px;
  }
}

.loop-slide {
  position: relative;
}

@media screen and (max-width: 768px) {
  .loop-slide {
    display: none !important;
  }
}

.loop-slide li {
  float: left;
}

.loop-slide img {
  display: block;
}

.top__body {
  padding: 35px 0px;
}

@media screen and (max-width: 768px) {
  .top__body {
    padding: 15px 15px 5px;
  }
}

.top__body img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.top__body h2 {
  position: relative;
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.41;
  padding-bottom: 0.5em;
  border-bottom: 5px solid rgba(0, 191, 255, 0.1);
}

@media screen and (max-width: 768px) {
  .top__body h2 {
    font-size: 18px;
  }
}

.top__body h2:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 5px;
  background: #00BFFF;
  content: "";
}

.top__body h3 {
  margin-bottom: 1em;
  padding-left: 12px;
  border-left: 3px solid #00BFFF;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .top__body h3 {
    font-size: 16px;
  }
}

.top__body h4 {
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .top__body h4 {
    font-size: 14px;
  }
}

.top__body p {
  margin-bottom: 1.5em;
  font-size: 15px;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .top__body p {
    font-size: 13px;
    line-height: 1.7;
  }
}

.top__body p a {
  color: #00BFFF;
  text-decoration: underline;
  background-image: url(../img/icon/ico_link.png);
  background-size: 4px auto;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 12px;
}

.top__body p a:hover {
  text-decoration: none;
}

.top__body ul {
  margin-bottom: 1.5em;
}

.top__body ul li {
  position: relative;
  line-height: 2;
  margin-bottom: 0.5em;
  padding-left: 14px;
}

@media screen and (max-width: 768px) {
  .top__body ul li {
    line-height: 1.7;
    font-size: 13px;
  }
}

.top__body ul li:before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 191, 255, 0.3);
  content: "";
}

@media screen and (max-width: 768px) {
  .top__body ul li:before {
    top: 7px;
  }
}

.top__body table {
  width: 100%;
  border-top: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  .top__body table {
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.top__body table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .top__body table th {
    width: 100px;
  }
}

.top__body table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

#article {
  padding-bottom: 60px;
}

.to-archives {
  text-align: center;
  font-weight: bold;
}

.to-archives a {
  text-decoration: underline;
}

.to-archives a:hover {
  text-decoration: none;
}

/* ------------------------------------------------
  page
---------------------------------------------- */
.page-ttl {
  font-size: 50px;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .page-ttl {
    padding-top: 20px;
    font-size: 25px;
    line-height: 1.2;
  }
}

.page-sub-ttl {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .page-sub-ttl {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.page-list-ttl {
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .page-list-ttl {
    margin-bottom: 15px;
    font-size: 18px;
    text-align: center;
  }
}

.pager {
  text-align: center;
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .pager {
    padding-bottom: 40px;
  }
}

.pager__item {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 5px;
  box-sizing: border-box;
  background: #fff;
  border: 3px solid #fff;
  text-align: center;
  line-height: 29px;
  vertical-align: middle;
  font-size: 16px;
  margin: 0 3px;
}

@media screen and (max-width: 768px) {
  .pager__item {
    font-size: 13px;
    width: 28px;
    height: 28px;
    line-height: 22px;
  }
}

.pager__item a {
  display: block;
  color: #00BFFF;
  font-weight: bold;
  text-decoration: none;
  transition: all 300ms;
}

.pager__item a:hover {
  background-color: rgba(0, 191, 255, 0.07);
  color: #8f8887;
}

.pager .current a {
  background-color: rgba(230, 249, 255, 0.3);
  color: #8f8887;
}

.pager img {
  display: inline-block;
  width: 4px;
  vertical-align: middle;
  margin-top: -4px;
}

.no-data {
  text-align: center;
  padding: 40px 50px;
  border: 1px solid #e2e2e2;
  background: #fff;
  margin-bottom: 80px;
  margin-top: 40px;
}

.main-contents {
  margin-top: 20px;
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .main-contents {
    padding: 30px 0;
  }
}

.main-contents__inner {
  padding: 40px 50px;
  border: 1px solid #e2e2e2;
  background: #fff;
}

.main-contents > .container {
  padding: 50px 0;
}

.main-contents > .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .main-contents > .container {
    padding: 15px 15px;
  }
}

.main-contents__ttl {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .main-contents__ttl {
    font-size: 24px;
  }
}

.main-contents__ttl-sub {
  margin-top: -10px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .main-contents__ttl-sub {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.main-contents__bg {
  width: 100%;
  height: 158px;
  background-repeat: repeat-x;
  background-position: left top;
  background-size: auto 100%;
  animation: bgAnime 30s linear infinite;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .main-contents__bg {
    height: 75px;
  }
}

.main-contents table {
  border-top: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  .main-contents table {
    width: 100%;
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.main-contents table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .main-contents table th {
    width: 100px;
  }
}

.main-contents table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.main-contents__dtl {
  margin-right: 580px;
}

@media screen and (max-width: 768px) {
  .main-contents__dtl {
    margin-right: 0;
  }
}

.main-contents__img {
  margin-bottom: 10px;
}

.main-contents__img img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.main-contents__txt {
  font-weight: bold;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  .main-contents__txt {
    font-size: 12px;
    line-height: 1.6;
  }
}

.main-contents__map {
  width: 100%;
  height: 350px;
}

@media screen and (max-width: 768px) {
  .main-contents__map {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .main-contents-company {
    padding: 30px 0;
  }
}

.main-contents-company > .container {
  padding: 50px 0;
}

.main-contents-company > .container:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .main-contents-company > .container {
    padding: 15px 15px;
  }
}

.main-contents-company .main-contents__inner {
  overflow: hidden;
}

.main-contents-company table {
  width: 440px;
  float: right;
  margin-left: 30px;
  border-top: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  .main-contents-company table {
    margin: 0;
    width: 100%;
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.main-contents-company table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .main-contents-company table th {
    width: 100px;
  }
}

.main-contents-company table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.main-contents-company img {
  max-width: 420px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .main-contents-company img {
    display: block;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.contents .article-list {
  padding-top: 40px;
  padding-bottom: 80px;
}

/* ------------------------------------------------
  article
---------------------------------------------- */
.article {
  margin-bottom: 60px;
}

.article:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .article {
    margin-bottom: 40px;
    margin-top: 15px;
  }
}

.article__ttl-box {
  position: relative;
  width: 340px;
  margin-bottom: 20px;
  background: #fff;
  float: right;
  box-sizing: border-box;
  padding: 30px 20px 20px 10px;
}

@media screen and (max-width: 768px) {
  .article__ttl-box {
    padding: 15px;
    float: none;
    width: 100%;
    margin-bottom: 0;
  }
}

.article__ttl-box:before {
  position: absolute;
  top: 0;
  left: -20px;
  width: 20px;
  height: 100%;
  border-left: 3px solid #00BFFF;
  background: #fff;
  content: "";
}

@media screen and (max-width: 768px) {
  .article__ttl-box:before {
    display: none;
  }
}

.article__side {
  float: right;
  width: 320px;
  box-sizing: border-box;
  border: 1px solid #e2e2e2;
  padding: 10px 20px;
  background: rgba(230, 249, 255, 0.3);
}

@media screen and (max-width: 768px) {
  .article__side {
    float: none;
    width: 100%;
    padding: 10px 15px;
  }
}

.article__side-ttl {
  margin-bottom: 10px;
  font-weight: bold;
}

.article__side-list-item {
  margin-bottom: 20px;
}

.article__side-list .wrap:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.article__side-list .wrap:hover .article__side-list-ttl {
  text-decoration: underline;
}

.article__side-list-thumb {
  width: 100px;
  height: 100px;
  overflow: hidden;
  float: left;
}

@media screen and (max-width: 768px) {
  .article__side-list-thumb {
    width: 80px;
    height: 80px;
  }
}

.article__side-list-thumb img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 100%;
}

.article__side-list-category {
  margin-left: 120px;
  color: #00BFFF;
  font-weight: bold;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .article__side-list-category {
    margin-left: 95px;
    font-size: 10px;
  }
}

.article__side-list-ttl {
  margin-left: 120px;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .article__side-list-ttl {
    margin-left: 95px;
    font-size: 11px;
  }
}

.article__meta {
  margin-bottom: 10px;
}

.article__meta:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

.article__meta-item {
  float: left;
  font-size: 12px;
  padding: 8px 20px;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .article__meta-item {
    font-size: 10px;
  }
}

.article__meta-item--category {
  background: #00BFFF;
  color: #fff;
  font-weight: bold;
}

.article__meta-item--category a {
  color: #fff;
  transition: opacity 300ms;
}

.article__meta-item--category a:hover {
  opacity: .6;
  text-decoration: none;
}

.article__meta-item--date {
  padding: 8px 15px 8px 30px;
  background-color: rgba(115, 134, 140, 0.2);
  background-image: url(../img/icon/ico_date.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 11px auto;
}

.article__ttl {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .article__ttl {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.article__tag-item {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 5px;
  padding: 5px 8px;
  background: rgba(115, 134, 140, 0.2);
  font-size: 11px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .article__tag-item {
    font-size: 10px;
  }
}

.article__main-img img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.article__body {
  width: 660px;
  float: left;
  box-sizing: border-box;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .article__body {
    float: none;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 1px;
  }
}

.article__body-inner {
  padding: 30px 50px;
}

@media screen and (max-width: 768px) {
  .article__body-inner {
    padding: 15px 15px 5px;
  }
}

.article__body-inner img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.article__body-inner h2 {
  position: relative;
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.41;
  padding-bottom: 0.5em;
  border-bottom: 5px solid rgba(0, 191, 255, 0.1);
}

@media screen and (max-width: 768px) {
  .article__body-inner h2 {
    font-size: 18px;
  }
}

.article__body-inner h2:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 5px;
  background: #00BFFF;
  content: "";
}

.article__body-inner h3 {
  margin-bottom: 1em;
  padding-left: 12px;
  border-left: 3px solid #00BFFF;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .article__body-inner h3 {
    font-size: 16px;
  }
}

.article__body-inner h4 {
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .article__body-inner h4 {
    font-size: 14px;
  }
}

.article__body-inner p {
  margin-bottom: 1.5em;
  font-size: 15px;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .article__body-inner p {
    font-size: 13px;
    line-height: 1.7;
  }
}

.article__body-inner p a {
  color: #00BFFF;
  text-decoration: underline;
  background-image: url(../img/icon/ico_link.png);
  background-size: 4px auto;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 12px;
}

.article__body-inner p a:hover {
  text-decoration: none;
}

.article__body-inner ul {
  margin-bottom: 1.5em;
}

.article__body-inner ul li {
  position: relative;
  line-height: 2;
  margin-bottom: 0.5em;
  padding-left: 14px;
}

@media screen and (max-width: 768px) {
  .article__body-inner ul li {
    line-height: 1.7;
    font-size: 13px;
  }
}

.article__body-inner ul li:before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 191, 255, 0.3);
  content: "";
}

@media screen and (max-width: 768px) {
  .article__body-inner ul li:before {
    top: 7px;
  }
}

.article__body-inner table {
  width: 100%;
  border-top: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  .article__body-inner table {
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.article__body-inner table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .article__body-inner table th {
    width: 100px;
  }
}

.article__body-inner table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.article__sns {
  margin: 10px 10px 15px;
  border: 1px solid #e2e2e2;
  padding: 10px 20px;
  background: rgba(115, 134, 140, 0.1);
}

@media screen and (max-width: 768px) {
  .article__sns {
    padding: 10px;
  }
}

.article__sns li {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .article__sns li {
    margin-bottom: 5px;
  }
}

.article__sns #___plusone_0 {
  width: 60px !important;
}

.article__autor {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
}

.article__autor:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .article__autor {
    padding-bottom: 15px;
  }
}

.article__autor:before {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  content: "";
}

.article__autor-thumb {
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  box-sizing: border-box;
  float: left;
  border-radius: 50%;
}

.article__autor-thumb img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
  border-radius: 50%;
}

.article__autor-name {
  margin-left: 95px;
  padding-top: 15px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 2px;
}

.article__autor-dtl {
  margin-left: 95px;
  font-size: 13px;
  margin-bottom: 10px;
}

.article__autor-dtl:before {
  content: "-";
  margin-right: 5px;
}

.article__autor-txt {
  clear: both;
  line-height: 1.6;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .article__autor-txt {
    font-size: 11px;
  }
}

/* ------------------------------------------------
  contact
---------------------------------------------- */
.contact {
  margin-top: 20px;
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .contact {
    padding-bottom: 40px;
  }
}

.contact__inner {
  padding: 40px 50px;
  border: 1px solid #e2e2e2;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .contact__inner {
    padding: 20px 15px;
  }
}

.contact__inner__1st {
  display: block;
  margin-bottom: -40px;
  z-index: 0;
  position: relative;
}

.contact__inner__1st img {
  width: 100%;
}

.contact__inner__2nd {
  width: 90%;
  padding-top: 40px;
  padding-left: 3%;
  padding-right: 3%;
  margin: 0 auto;
  background-color: #fff;
  z-index: 10;
  position: relative;
}

.contact__inner_top {
  padding: 0 0 40px;
}

.contact__ttl {
  position: relative;
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.41;
  padding-bottom: 0.5em;
  border-bottom: 5px solid rgba(0, 191, 255, 0.1);
}

@media screen and (max-width: 768px) {
  .contact__ttl {
    font-size: 18px;
  }
}

.contact__ttl:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 5px;
  background: #00BFFF;
  content: "";
}

.contact__txt {
  margin-bottom: 1.5em;
  font-size: 15px;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .contact__txt {
    font-size: 13px;
    line-height: 1.7;
  }
}

.contact__table {
  width: 100%;
  margin-bottom: 24px;
  border-top: 1px solid #b9b9c8;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .contact__table {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .contact__table tr {
    display: block;
  }
}

.contact__table th {
  padding: 12px 20px;
  border-bottom: 1px solid #b9b9c8;
  background: rgba(230, 249, 255, 0.2);
  font-weight: bold;
  color: #525252;
}

@media screen and (max-width: 768px) {
  .contact__table th {
    display: block;
    padding: 8px 0;
    text-align: center;
  }
}

.contact__table td {
  padding: 12px 30px;
  border-bottom: 1px solid #b9b9c8;
}

@media screen and (max-width: 768px) {
  .contact__table td {
    display: block;
    padding: 12px 10px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .contact__item {
    display: block;
  }
  .contact__item + .contact__item {
    margin-top: 10px;
  }
}

.contact__note {
  width: 700px;
  margin: 0 auto 30px;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .contact__note {
    width: auto;
    margin: 0 auto 15px;
    font-size: 12px;
  }
}

.contact__note a {
  color: #00BFFF;
  text-decoration: underline;
}

.contact__note a:hover {
  text-decoration: none;
}

.contact__submit-btn {
  margin: 0 auto 20px;
  min-width: 250px;
  appearance: none;
  border: none;
  border-radius: 4px;
  background: #00BFFF;
  padding: 10px 30px;
  font-size: 20px;
  letter-spacing: 0.2em;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .contact__submit-btn {
    width: 100%;
    font-size: 16px;
    min-width: inherit;
  }
}

.contact__cancel-btn {
  display: block;
  margin: 0 auto 20px;
  border-radius: 4px;
  background: #aaa;
  padding: 10px 15px;
  font-size: 20px;
  letter-spacing: 0.2em;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .contact__cancel-btn {
    display: inline-block;
    font-size: 16px;
  }
}

.contact .must {
  display: inline-block;
  color: #c70000;
  font-size: 11px;
  margin: 0 2px;
  vertical-align: top;
}

.contact .before-txt {
  margin-right: 10px;
}

.contact .input-txt {
  appearance: none;
  border: 1px solid #e2e2e2;
  font-size: 16px;
  padding: 6px 10px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
}

.contact .input-txt + .before-txt {
  margin-left: 20px;
}

.contact .input-txt--1 {
  width: 120px;
}

@media screen and (max-width: 768px) {
  .contact .input-txt--1 {
    width: 150px;
  }
}

.contact .input-txt--2 {
  width: 300px;
}

@media screen and (max-width: 768px) {
  .contact .input-txt--2 {
    width: 100%;
  }
}

.contact__btn-list {
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contact__btn-list {
    display: flex;
    flex-direction: column;
  }
}

.contact__btn-list li {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .contact__btn-list li {
    display: block;
    order: 2;
  }
}

.contact__btn-list li + li {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .contact__btn-list li + li {
    margin-left: 0;
    order: 1;
  }
}
