/* ------------------------------------------------
  contact
---------------------------------------------- */
.contact {
	margin-top: 20px;
	padding-bottom: 80px;
	@include mq(sp) {
		padding-bottom: 40px;
	}
	&__inner {
		padding: 40px 50px;
		border: 1px solid #e2e2e2;
		background: #fff;
		@include mq(sp) {
			padding: 20px 15px;
		}
		&__1st {
			display:block;
			margin-bottom:-40px;
			z-index:0;
			position: relative;
			img{
				width: 100%;
			}
		}
		&__2nd {
			width:90%;
			padding-top:40px;
			padding-left:3%;
			padding-right:3%;
			margin:0 auto;
			background-color:#fff;
			z-index:10;
			position: relative;
		}
	}
		&__inner_top{
		padding: 0 0 40px;
	}

	&__ttl {
		position: relative;
		margin-bottom: 1em;
		font-size: 20px;
		font-weight: bold;
		line-height: 1.41;
		padding-bottom: 0.5em;
		border-bottom: 5px solid rgba($main_color,.1);
		@include mq(sp) {
			font-size: 18px;
		}
		&:before {
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 50px;
			height: 5px;
			background: $main_color;
			content: "";
		}
	}
	&__txt {
		margin-bottom: 1.5em;
		font-size: 15px;
		line-height: 2;
		@include mq(sp) {
			font-size: 13px;
			line-height: 1.7;
		}
	}
	&__table {
		width: 100%;
		margin-bottom: 24px;
		border-top: 1px solid #b9b9c8;
		font-size: 16px;
		@include mq(sp) {
			font-size: 13px;
		}
		tr {
			@include mq(sp) {
				display: block;
			}
		}
		th {
			padding: 12px 20px;
			border-bottom: 1px solid #b9b9c8;
			background: rgba(lighten($main_color,45%),.2);
			font-weight: bold;
			color: #525252;
			@include mq(sp) {
				display: block;
				padding: 8px 0;
				text-align: center;
			}
		}
		td {
			padding: 12px 30px;
			border-bottom: 1px solid #b9b9c8;
			@include mq(sp) {
				display: block;
				padding: 12px 10px;
				text-align: center;
			}
		}
	}
	&__item {
		@include mq(sp) {
			display: block;
			+.contact__item {
				margin-top: 10px;
			}
		}
	}
	&__note {
		width: 700px;
		margin: 0 auto 30px;
		font-size: 13px;
		@include mq(sp) {
			width: auto;
			margin: 0 auto 15px;
			font-size: 12px;
		}
		a {
			color: $main_color;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	&__submit-btn {
		margin: 0 auto 20px;
		min-width: 250px;
		appearance: none;
		border: none;
		border-radius: 4px;
		background: $main_color;
		padding: 10px 30px;
		font-size: 20px;
		letter-spacing: 0.2em;
		color: #fff;
		font-weight: bold;
		cursor: pointer;
		@include mq(sp) {
			width: 100%;
			font-size: 16px;
			min-width: inherit;
		}
	}
	&__cancel-btn {
		display: block;
		margin: 0 auto 20px;
		border-radius: 4px;
		background: #aaa;
		padding: 10px 15px;
		font-size: 20px;
		letter-spacing: 0.2em;
		color: #fff;
		font-weight: bold;
		cursor: pointer;
		@include mq(sp) {
			display: inline-block;
			font-size: 16px;
		}
	}
	.must {
		display: inline-block;
		color: #c70000;
		font-size: 11px;
		margin: 0 2px;
		vertical-align: top;
	}
	.before-txt {
		margin-right: 10px;
	}
	.input-txt {
		appearance: none;
		border: 1px solid #e2e2e2;
		font-size: 16px;
		padding: 6px 10px;
		box-sizing: border-box;
		text-align: left;
		font-size: 16px;
		+.before-txt {
			margin-left: 20px;
		}
		&--1 {
			width: 120px;
			@include mq(sp) {
				width: 150px;
			}
		}
		&--2 {
			width: 300px;
			@include mq(sp) {
				width: 100%;
			}
		}
	}
	&__btn-list {
		margin-bottom: 20px;
		text-align: center;
		@include mq(sp) {
			display: flex;
			flex-direction: column;
		}
		li {
			display: inline-block;
			@include mq(sp) {
				display: block;
				order: 2;
			}
			+li {
				margin-left: 20px;
				@include mq(sp) {
					margin-left: 0;
					order: 1;
				}
			}
		}
	}
}