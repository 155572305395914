/* gnav
-------------------------*/
.gnav {
  background: $main_color;
	z-index: 90;
	@include mq(sp) {
		position: fixed;
		top: 0;
		right: -260px;
		width: 260px;
		height: 100%;
		box-sizing: border-box;
		text-align: left;
		transition: right 300ms;
	}
	.container {
		position: relative;
		@include mq(sp) {
			height: 100%;
			overflow: auto;
			padding: 0;
		}
	}
  &__header-list {
		position: absolute;
		top: -100%;
		right: 0;
		margin-top:7px;
    margin-bottom: 17px;
		@include mq(sp) {
			position: relative;
			top: 0;
			right: 0;
			@include clearfix;
			border-top: 1px solid rgba(#fff,.8);
		}
  }
	&__header-item {
    display: inline-block;
    border-left: 1px dotted #6A6A6A;
    line-height: 1;
    padding: 0 10px;
    font-size: 12px;
    @include mq(sp) {
			display: block;
			border-left: none;
			padding: 0;
			width: 50%;
			float: left;
			border-right: 1px solid rgba(#fff,.8);
			border-bottom: 1px solid rgba(#fff,.8);
			box-sizing: border-box;
			&:nth-child(even) {
				border-right: none;
			}
		}
    &:first-child {
      border-left: none;
    }
    a {
      display: block;
      color: #535353;
			@include mq(sp) {
				padding: 15px 10px;
				color: #fff;
				font-weight: bold;
			}
    }
  }
  &__list {
    width: 100%;
    text-align: center;
		@include mq(sp) {
			text-align: left;
			margin-bottom: 40px;
		}
  }
  &__item {
    display: inline-block;
    padding: 6px 0;
    @include mq(sp) {
			display: block;
			padding: 0;
			border-bottom: 1px solid rgba(#fff,.8);
		}
    a {
      position: relative;
      display: block;
      padding: 6px 15px;
      border-radius: 2px;
      text-decoration: none;
      color: #fff;
      font-weight: bold;
      //text-shadow: 0 0 5px transparent;
      transition: all 300ms;
			@include mq(sp) {
				padding: 10px 15px;
				&:before {
					position: absolute;
					top: 50%;
					right: 15px;
					width: 6px;
					height: 6px;
					border-top: 2px solid #fff;
					border-right: 2px solid #fff;
					content: "";
					transform: rotate(45deg) translateY(-50%);
				}
			}
    }
  }
	&__ttl {
		display: none;
		margin-bottom: 5px;
		padding: 0 10px;
		color: #fff;
		font-weight: bold;
		@include mq(sp) {
			display: block;
		}
	}
}
.fixed {
	.gnav {
		position: fixed;
		top: 0;
		width: 100%;
		@include mq(sp) {
			width: 260px;
		}
	}
	+.contents {
		margin-top: 47px;
		@include mq(sp) {
			margin-top: 0;
		}
	}
}