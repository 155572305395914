/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.container {
  width: $container;
  margin: 0 auto;
	@include mq(sp) {
		width: auto;
		padding: 0 15px;
	}
}
.wrap,.fade {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration:none;
  }
}
.ib {
  display: inline-block;
}
.phoneTxt {
  text-decoration: none;
  cursor: default;
}
.is_pc {
  @include mq(sp) {
    display: none;
  }
}
.is_sp {
  display: none !important;
  @include mq(sp) {
    display: block !important;
  }
}
