//-----------------------------
// 変数宣言用ファイル
//-----------------------------

//-----------------
// メインカラー
//-----------------
$main_color: #00BFFF;
//-----------------
// ベーステキストカラー
//-----------------
$txt_color: #333;
//-----------------
// アイコンカラー
//-----------------
$icon_color: #FD6A62;
//-----------------
// 背景カラー
//-----------------
$bg_color: #fff;
//-----------------
// コンテンツ幅
//-----------------
$container: 1000px;